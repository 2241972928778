import React, { Component } from 'react';
import styled from 'styled-components/macro';

class LetterSplit extends Component {


  renderLetter(letter, index) {
    return <span key={index}>{letter}</span>
  }

  render() {

    const text = this.props.text
    const name = this.props.name
    
    let letters = []

    for (var i = 0; i < text.length; i++) {
      letters.push(text.charAt(i))
    }

    return (
      <Container name={name} data-lettersplit={true}>
        {letters.map(this.renderLetter)}
      </Container>
    );
  }
}

export default LetterSplit

const Container = styled.span`

`