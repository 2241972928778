import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";
import styled from 'styled-components/macro'

import { namedRoutes } from '../config/routing'
import { media } from '../config/globalStyles'

class SideNav extends Component {

  handleLinkClick = (event) => {
    event.preventDefault()
    const target = event.target.getAttribute('href')
    if (target.indexOf("//") > -1 ) {
      setTimeout( () => {
        window.location = target
      },50)      
    } else {
      this.props.history.push(target)
    }
  }

  render() {

    return (
      <Nav>
        <Ul>
          <Li>
            <Link to={namedRoutes.imprint_privacy} onClick={this.handleLinkClick}>
              {this.props.data.menu_text_imprint_privacy}
            </Link>
          </Li>
        </Ul>
      </Nav>
    );
  }
}

export default withRouter(SideNav)

const Nav = styled.nav`
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
`

const Ul = styled.ul`
  transform: rotate(-90deg) translateY(-150%)  translateX(calc(50% + 16px));
  display: flex;
  justify-content: space-evenly;
`

const Li = styled.li`
  font-size: 10px;
  opacity: 0.9;
  // text-shadow: 2px 2px rgba(0,0,0,0.5);

  &:not(:first-child) {
    padding-left: 1em;
  }

  a {
    text-decoration: none;
    &:hover {
      transform: skew(15deg, 15deg);
    }
  }
`