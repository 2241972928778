import React, { Component } from 'react';
import fetch from 'fetch-hoc'
import { BrowserRouter } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components/macro'

import 'reset-css';
import './App.css';

import {Content, Wrapper} from './components'
import {networkConfig} from './config/network'

const GlobalStyle = createGlobalStyle`
  @import url('${ props => props.font_import_url}');
  * {
    font-family: '${ props => props.font_family}', sans-serif !important;  
  }
`

class App extends Component {


  render() {

    let content = null

    if (this.props.error) {
      content = <div className="error">{`An error occured! ${this.props.error}`}</div>
    } else if (this.props.loading) {
      content = <div className="loading">Loading...</div>
    } else {
      content = <Content data={this.props.data} />
    }

    return <Wrapper>
      <GlobalStyle 
        font_import_url={this.props.data ? this.props.data.font_import_url : null}
        font_family={this.props.data ? this.props.data.font_family : null}        
      />
      <BrowserRouter>
        {content}
      </BrowserRouter>
    </Wrapper>
  }
}

export default fetch(networkConfig.dataUrl)(App)