const networkConfig = {
  dataUrl: process.env.REACT_APP_DATA_SOURCE === 'cockpit'
    ? 'http://cockpit.karmanoia.org/api/singletons/get/Website?token=788eacc2601ff28d91207cedb88bb2'
    : 'https://karmanoia.org/new/data.json',
  assetsPrefix: 'http://cockpit.karmanoia.org',
  // miceUrl: 'http://localhost:8080',
  // miceUrl: 'https://karmice-server.herokuapp.com'
  miceUrl: 'https://karmice.intergestalt.dev'
}

const getAssetUrl = (asset) => {
  return networkConfig.assetsPrefix + asset.path
}

module.exports = {networkConfig, getAssetUrl}