import React, { Component } from 'react';
import styled from 'styled-components'

import { getAssetUrl } from '../config/network';
import { getRouteName } from '../config/routing';

const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
const disableTransition = isFirefox

class Background extends Component {

  render() {    

    const routeName = getRouteName(this.props.location.pathname)

    const assetHome = this.props.data.page_main_background_image
    const assetInfo = this.props.data.page_info_background_image

    const filterHome = filterFilter(this.props.data.page_main_background_filter)
    const filterInfo = filterFilter(this.props.data.page_info_background_filter)

    return (
      <div>
        <Img xfilter={filterHome} src={getAssetUrl(assetHome)} active={routeName === "home"}/>
        <Img xfilter={filterInfo} src={getAssetUrl(assetInfo)} active={routeName === "info"}/>
      </div>
    );
  }
}

export default Background

const filterFilter = (filterString) => {
  if (RegExp(/[a-z0-9% \(\)]*/).test(filterString)) {
    return filterString
  } else {
    return false
  }
}

const Img = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
  z-index: 0;
  will-change: opacity;
  opacity: ${props => props.active ? 1 : 0};
  ${ disableTransition ? null : `transition: opacity 1.5s 0.2s;` }
  // transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  pointer-events: none;
  user-select: none;
  ${ props => props.xfilter ? `filter:${props.xfilter};` : null }
`