import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from "react-router";
import styled, { keyframes, css } from 'styled-components/macro'
import { createGlobalStyle } from 'styled-components'

import { namedRoutes } from '../config/routing'
import { media, dist } from '../config/globalStyles'

const GlobalStyle = createGlobalStyle`
  body {
    transition: opacity 0.8s 0.5s;
    opacity: ${props => (props.opaque ? '1' : '0')};
  }
  html {
    background-color: #180524;
  }
`

class MainNav extends Component {
  constructor() {
    super()
    this.state = {
      anims: {}
    }
  }

  triggerAnim = (name) => {
    if (name === "visit") {
      this.setState({
        anims: {
          visit: css`animation: ${flee} 4s`
        }
      },() => {
        setTimeout( () =>
        this.setState({anims: {}})
        , 1500 )
      })
    }
  }

  handleLinkClick = (event) => {
    event.preventDefault()
    const target = event.currentTarget
    this.triggerAnim(target.name)
    const href = target.getAttribute('href')
    if (href.indexOf("//") > -1 ) {
      setTimeout( () => {
        window.location = href
      },1000)      
    } else {
      setTimeout( () => {
        this.props.history.push(href)
      },20)
    }
  }

  render() {

    return (
      <Nav>
        <Ul>
          <Li active={namedRoutes.info === this.props.history.location.pathname}>
            <Link to={namedRoutes.info} onClick={this.handleLinkClick} style={{transform: "rotateZ(-1deg)"}}>
              <Img src="/mainNav/Inform.png" alt={this.props.data.menu_text_info} />
            </Link>
          </Li>
          <Li active={namedRoutes.home === this.props.history.location.pathname}>
            <Link to={namedRoutes.home} onClick={this.handleLinkClick} style={{transform: "rotateZ(1deg)"}}>
              <Img src="/mainNav/Karmanoia.png" alt={this.props.data.menu_text_info} />
            </Link>
          </Li>
          <Li anim={this.state.anims.visit}>
            <A rel="prefetch" name="visit" href={this.props.data.menu_link_visit} onClick={this.handleLinkClick}>
              <Img src="/mainNav/Visit.png" alt={this.props.data.menu_text_visit} />
            </A>
          </Li>
        </Ul>
        <GlobalStyle opaque={!this.state.anims.visit} />
      </Nav>
    );
  }
}

export default withRouter(MainNav)

const spacings = `
  padding: 2vw 0 3vw 0;
  margin-bottom: 1em;
  font-size: 5vw;
`
const Img = styled.img`
  height: 2em;
  width: auto;
  transition: filter 0.2s;
  filter: contrast(50%) brightness(180%);
  transform: scale(1.1);
  ${ media.large } {
    filter: none;
    transform: scale(1);
    }
  &:active {
    filter: invert(30%) contrast(140%);
  }
`

const Nav = styled.nav`
  ${spacings}
`

const Ul = styled.ul`
  position: fixed;
  top:0;
  z-index: 3;
  //background-color: rgba(0,255,255,0.5);

  max-width: ${dist.largeWidth}px;
  width: calc(100% - 4rem);
  margin: auto;
  justify-content: space-between;
  display: flex;
  ${ media.large } {
    justify-content: space-evenly;
  }
  ${spacings}
  margin-top: 0.4em;
`

const Li = styled.li`
  font-weight: 900;
  font-size: 5vw;
  transform: scale(1.1) translateY(-15%);
  transform-origin: 50% 100%;
  // will-change: transform;
  // transition: transform 1s;
  // transform-origin: 50% 100%;
  // ${ props => props.active ? null : 'transform: scale(0.75)' };
  ${ media.xLarge } {
    font-size: 3.2rem;
  };
  // text-shadow: 2px 2px rgba(0,0,0,0.5);

  ${ props => (props.anim ? props.anim : null) };

  &:not(:first-child) {
    padding-left: 1em;
  }

  a {
    display: block;
    &, * { 
      // transition: color 1s;
      will-change: color;
      color: ${ props => props.active ? 'white' : 'red' }; 
    }
    text-decoration: none;
    &:active {
      color:blue !important;
    }
  }
`;

const A = styled.a``

const float = keyframes`
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(20%);
  }
`;

const flee = keyframes`
  0% {
    transform: translateY(0%) translateX(0%);
  }

  90% {
    transform: translateY(100vh) translateX(10%);
  }

  100% {
    transform: translateY(0%) translateX(0%);
  } 
`;
