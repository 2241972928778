import React, { Component } from 'react';
import styled from 'styled-components/macro';

class Wrapper extends Component {
  render() {

    return (
      <Container>
        {this.props.children}
      </Container>
    );
  }
}

export default Wrapper

const Container = styled.div`
  color: white;
  background-color: black;
  min-height: 100vh;
  font-family: sans-serif;
  min-height: 100vh;
`