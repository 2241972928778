import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import { withRouter } from "react-router";
import styled from 'styled-components/macro'

import { Mice, Social, MainNav, SideNav, InfoPage, HomePage, ImprintPage, Background } from './'
import { namedRoutes } from '../config/routing'
import { dist, media } from '../config/globalStyles'

class Content extends Component {

  componentWillReceiveProps(nextProps) {

    const { location, history: { action } } = nextProps;
    if (location !== this.props.location && action === 'PUSH') {
      // new navigation - scroll to top
      window.scrollTo(0, 0);
   }
  }

  render() {

  return (
    <>
      <Background {...this.props} />
      <Mice />
      <Social items={this.props.data.social_links.map( i => i.value )}/>
      <Container>
        <MainNav data={this.props.data}/>
        <Main>
          <Switch>
            <Route exact path={namedRoutes.home} render={(props) => (
              <HomePage data={this.props.data}/>
            )}/>
            <Route path={namedRoutes.imprint_privacy} render={(props) => (
              <ImprintPage data={this.props.data}/>
            )}/>                        
            <Route path={namedRoutes.inform} render={(props) => (
              <InfoPage data={this.props.data}/>
            )}/>
          </Switch>
        </Main>
        {/*<code style={{fontSize: '70%'}}>
          {JSON.stringify(this.props.data)}
        </code>*/}
      </Container>
      <SideNav data={this.props.data}/>      
    </>
    );
  }
}

export default withRouter(Content)

const Container = styled.div`
  position: relative;
  z-index: 1;
  padding: 2rem 2rem 3rem 2rem;
  ${ media.medium } {
    padding: 2rem 1rem 2rem 1rem;
  }
  max-width: ${dist.largeWidth}px;
  margin: auto;
  pointer-events: none;
  a, iframe, input, button {
    pointer-events: all;
  }
`

const Main = styled.div`
`
