import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';

import {  } from './'
import { getAssetUrl } from '../config/network';
import { media } from '../config/globalStyles';

class InfoPage extends Component {

  renderAssets = () => {
    const assets = this.props.data.page_info_images
    return assets.map( asset => (
      <Image 
        alt={asset.meta.title} 
        title={asset.meta.title}        
        key={asset.meta.asset} 
        src={ getAssetUrl(asset) } 
        />
    ))
  }

  render() {    

    return (
      <Markdown className="slanting-container">
        <ReactMarkdown source={this.props.data.page_info_text} />
        {this.renderAssets()}
      </Markdown>
    );
  }
}

export default InfoPage

const Image = styled.img`
  margin-bottom: 1rem;
  width: 100%;
  max-width: 40vh;
  margin: auto;
  display: block;
`

const Markdown = styled.div`
  ${ media.large } {
    padding-top: 4vh;
  }
  text-shadow: 4px 6px 4px rgba(0,0,0,0.6);
  opacity: 0.9;
  color: rgb(225,255,255)
  text-align: center;
  h1,h2,p,ol {
    margin-bottom: 1rem;
  }
  li {
    &:before, &:after {
      content: " * ";
      font-size: 150%;
      top: 0.5ex;
      position: relative;
    }
    &:not(:first-of-type) {
      margin-top: 1ex;
    }
  }

  &.slanting-container > * {
    &:nth-of-type(1) {
      transform: rotateZ(-0.5deg) translateX(-0.5%);
    }
    &:nth-of-type(2n) {
      transform: rotateZ(0.5deg) translateX(0.5%);
    }
    &:nth-of-type(2n+1) {
      transform: rotateZ(-0.7deg) translateX(-0.5%);
    }
  }

`