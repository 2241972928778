import React, { Component } from 'react';
import styled from 'styled-components/macro'
import Matter from 'matter-js';
import { debounce } from "debounce";

import { dist } from '../config/globalStyles'

const renderCanvas = false // for debugging, see matter.js objects
const gravityScale = 0.00045 // how strong gravity
const throwScale = 0.18 // how strong the throw
const shapeScale = 1.2 // size of shape compared to size of element
const airFriction = 0.003 // air friction of the shape
const throwDelay = 1500 // time until throw after load
const settleDelay = 60000 // maximum time until the render stops
const throwDelaySpread = 1800 // time range in which all element get thrown
const settleSpeed = 0.6 // lower speed settles
const settleAngularSpeed = 0.0002 // lower speed settles
const respawnProbability = 0 // probability that they will fall again when navigating

class Social extends Component {

  constructor() {
    super();
    this.engine = null;
    this.world = null;
    this.runner = null;
    this.state = {
      activeMatter: false
    }
    this.renderItems = this.renderItems.bind(this)
  }

  componentDidMount() {

    this.viewportHeight = window.innerHeight;
    this.viewportWidth = window.innerWidth;

    setTimeout(()=>{
      this.setState({activeMatter:true})
    },throwDelay)

    /* window.addEventListener("resize", (event) => {
      const deltaY = this.viewportHeight - event.srcElement.innerHeight
      if ( this.viewportWidth === event.srcElement.innerWidth 
        && Math.abs( deltaY ) < maxDeltaY ) {
          this.setState({offsetY: deltaY})
        } else {
          window.addEventListener("resize", debounce(this.renderMatter.bind(this), 2000))
          window.addEventListener("resize", debounce(this.dissolveMatter.bind(this), 2000, true))
        }
        console.log(Math.abs( this.viewportHeight - event.srcElement.innerHeight ))
    }) */

    setTimeout(this.settle, settleDelay)

  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log(nextState, nextProps)
    if (this.state.activeMatter && Math.random() > respawnProbability) return false
    else return true
  }

  dissolveMatter = () => {
    console.log(this)
    if (this.world) Matter.World.remove(this.world, this.floor)
  }

  handleClick = (event,url) => {
    // console.log(url)
    window.open(url, '_blank');
    // event.target.querySelector("a").click()
  }

  renderItems(item) {
    const style = {
      backgroundColor: item.background_color,
      color: item.text_color
    }
    return <li key={item.name}>
      <svg style={{ width: 50, height: 50}}>
        <a title={item.name} style={style} href={item.url} target="_blank"  rel="noopener" onClick={(event) => this.handleClick(event,item.url)}>
          <g>
            <polygon points="0,27.5 40,50 40,0" fill={item.background_color} />
            <text x="20" y="30" stroke={item.text_color} fill={item.text_color}>
              {item.symbol}
            </text>
          </g>
        </a>
      </svg>
    </li>
  }

  renderMatter() {

    const element = this.stage
    if (!element) return;

    if (this.engine) {
      this.stopMatter();
      Matter.Engine.clear(this.engine)
      this.engine = {}
      console.log("matters cleared")
    }


    const width = element.offsetWidth;
    const height = element.offsetHeight;
    // console.log(element, width, height)


    var Example = Example || {};

    Example.mixed = () => {
        var Engine = Matter.Engine,
            Render = Matter.Render,
            Runner = Matter.Runner,
            Common = Matter.Common,
            World = Matter.World,
            Bodies = Matter.Bodies;
    
        // create engine
        this.engine = Engine.create()
        this.world = this.engine.world

        this.world.gravity.scale = gravityScale
    
        // create renderer

        if (renderCanvas) {

          this.render = Render.create({
            element: element,
            engine: this.engine,
            options: {
              width,
              height,
              showAngleIndicator: true,
            }
          });
      
          Render.run(this.render);
          
        }
    
        // create runner
        this.runner = Runner.create();
        Runner.run(this.runner, this.engine);

        // adjustments
        const windowWidth = window.innerWidth;
        const adjustedAirFriction = airFriction/(windowWidth*0.1)
        const adjustedThrowScale = throwScale * (windowWidth*0.002)        

        // add bodies

        var bodiesDom = document.querySelectorAll('#social li');
        var bodies = [];
        for (var i = 0, l = bodiesDom.length; i < l; i++) {
            var bodyWidth = bodiesDom[i].offsetWidth / 2.283
            var body = Bodies.polygon(
                20,
                -20, 
                3, 
                bodyWidth * shapeScale,
            );
            body.frictionAir = adjustedAirFriction
          bodiesDom[i].id = body.id;
            bodies.push(body);
        }

        bodies.forEach((body) =>{
          setTimeout(()=>{
            // console.log(body)
            World.addBody(this.engine.world, body);
            let strength = Matter.Common.random(0.04, 0.1) * adjustedThrowScale
            Matter.Body.applyForce(body, {x:0, y:0}, {x:strength, y:0})
          },Common.random(0,throwDelaySpread))
        })


        // move HTML elements
        const scale = 1;

        window.requestAnimationFrame(update);

        const stopIt = this.settle

        function update() {
            for (var i = 0, l = bodiesDom.length; i < l; i++) {
                var bodyDom = bodiesDom[i];
                var body = null;
              for (var j = 0, k = bodies.length; j < k; j++) {
                  if ( bodies[j].id == bodyDom.id ) {
                        body = bodies[j];
                        break;
                    }
                }
                
                if ( body === null ) continue;
                
                bodyDom.style.transform = "translate( " 
                    + ((body.position.x) * scale - bodyDom.offsetWidth/2 ) 
                    + "px, "
                    + (( body.position.y) * scale - bodyDom.offsetHeight/2)
                    + "px )";
                bodyDom.style.transform += "rotate( " + body.angle + "rad )";
                
            }

            // check speed and stop if slow
            const totalSpeed = bodies.reduce( (accumulator, body) => (accumulator + body.speed), 0)
            const totalAngularSpeed = bodies.reduce( (accumulator, body) => (accumulator + body.angularSpeed), 0)
            // console.log(totalSpeed, totalAngularSpeed)
            if (totalSpeed > 0 && totalAngularSpeed > 0 && totalAngularSpeed < settleAngularSpeed && totalSpeed < settleSpeed) {
              stopIt()
            } else {
              window.requestAnimationFrame(update);
            }
        }

        const wallWidth = 50
        const wallPadding = dist.rem

        this.floor = Bodies.rectangle(0, height+(wallWidth/2)-wallPadding, 2*width, wallWidth, { isStatic: true })
    
        World.add(this.world, [
            // walls
            //Bodies.rectangle(0, -(wallWidth/2)+wallPadding, 2*width, wallWidth, { isStatic: true }), // top
            Bodies.rectangle(width+(wallWidth/2)-wallPadding, 0, wallWidth, 2*height, { isStatic: true }), // right
            this.floor, // bottom
            Bodies.rectangle(-(wallWidth/2)+wallPadding, 0, wallWidth, 2*height, { isStatic: true }), // left
        ]);
    
    };    

    Example.mixed()
  }

  stopMatter() {
    Matter.Render.stop(this.render);
    Matter.Runner.stop(this.runner);
  }

  settle = () => {
    console.log("settle")
    this.stopMatter()
  }

  render() {

    if (!this.state.activeMatter) return null;

    const items = this.props.items.reverse().map(this.renderItems)

    return (
          <WorldContainer 
            width = {this.viewportWidth}
            height = {this.viewportHeight}
            ref={ (b) => {this.stage = b; this.renderMatter(b)} } 
          >
            <ContainerUl id="social">
            {items}
          </ContainerUl>
        </WorldContainer>
    );
  }
}

export default Social

const ContainerUl = styled.ul`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  //background-color: rgba(255,255,0,0.5);
  li {
    position: absolute;
    left:0;
    top:0;
  }
  svg {
    &, * {pointer-events: all;}
    cursor: pointer;
    &:hover {
      filter: brightness(125%);      
    }
  }
  a {/*
    width: 2em;
    height: 2em;
    line-height: 2em;
    font-size:120%;
    display: inline-block;
    text-align: center;*/
    text-decoration: none;
    font-weight: bold;
  }
  text {
    color: green;
    font-size: 16px;
  }
`

const WorldContainer = styled.div`
  position: fixed;
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index:2;
  pointer-events: none;
  // background-color: rgba(255,0,0,0.5);

  canvas {
    background: transparent !important;
    // display: none;
  }
`