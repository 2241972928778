import React, { Component } from 'react';
import styled from 'styled-components/macro'
import { media } from '../config/globalStyles'

class NewsletterForm extends Component {
  constructor() {
    super()
    this.state = {}
  }

  input = () => {
    this.setState({ hasInput: true })
  }

  render() {    
    return (
      <Form id="newsletter_form" action="https://karmanoia.org/index.php?option=com_acymailing&ctrl=sub" method="post">
        <input style={{transform: "rotateZ(1deg) translateX(-0.4%)"}} id="user_name" required placeholder="Name"  type="text" name="user[name]" />
        <input style={{transform: "rotateZ(1deg)"}} id="user_email" required placeholder="Email" type="text" name="user[email]" onInput={this.input} />
        <input id="button" type="submit" value="Newsletter sign up" title="Newsletter sign up" />
        { this.state.hasInput && <div style={{textAlign:"center", width:"100%"}}>Warning: This button gives you a cookie</div> }
        <input type="hidden" name="user[html]" value="1" id="user_html_1" />
        <input type="hidden" name="acyformname" value="formAcymailing1" />
        <input type="hidden" name="ctrl" value="sub"/>
        <input type="hidden" name="task" value="optin"/>
        <input type="hidden" name="redirect" value="/"/>
        <input type="hidden" name="option" value="com_acymailing"/>
        <input type="hidden" name="visiblelists" value=""/>
        <input type="hidden" name="hiddenlists" value="1"/> {/*<!-- list number 1 -->*/}
      </Form>
    );
  }
}

export default NewsletterForm

const triangleRightCSS = (height, semiHeight, color) =>
  (`
    width: 0;
    height: 0;
    padding: 0;
    border-top: ${semiHeight} solid transparent;
    border-left: ${height} solid ${color};
    border-bottom: ${semiHeight} solid transparent;
  `)

const Form = styled.form`
  width: 50%;
  ${ media.medium } {
    width: 100%;
  }
  margin: auto;
  position: relative;
  margin-bottom: 2em;
  z-index: 2;

  input {
    box-sizing: border-box;
    font-size: 85%;
  }

  #user_name, #user_email {
    width: 100%;
    border: 2px solid rgba(200,255,255, 0.9); // rgba(200,255,255, 0.9)
    padding: 10px;
    height: calc(1em + 10px);
    background-color: rgba(140,140,140,0.4);
    color: white;
    font-weight: bold;
    box-sizing: border-box;
    margin-bottom: 1px;
    :placeholder-shown, ::placeholder {
      color: rgba(200,255,255, 0.9);
    }
    &:nth-child(1) {
      border-radius: 5px 5px 0 0;
    }
    &:nth-child(2) {
      border-radius: 0 0 5px 5px;
    }    
  }
  #button {
    display: block;
    color: black;
    padding: 1ex;
    margin: auto;
    margin-top: 1ex;
  }
`