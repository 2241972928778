import React, { Component } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components/macro';
import { media } from '../config/globalStyles'

import { NewsletterForm } from './'

class HomePage extends Component {

  renderVideo(video) {

    const opts = {
      height: 'auto',
      width: '100%',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: 0,
        showinfo: 0,
        controls: 1,
        modestbranding: 1,
        playlist: video.next_videos.map( i =>  i.value).join(","),
      }
    };
 
    return (
      <VideoContainer style={{transform: "rotateZ(-1deg)"}}>
        <ResponsiveContainer>
          <YouTube
            videoId={video.videoId}
            opts={opts}
            onReady={this._onReady}
          />
        </ResponsiveContainer>
      </VideoContainer>
    );
  }

  render() {
    return (
      <Container>
        <NewsletterForm />
        {this.renderVideo(this.props.data.page_main_video)}
      </Container>
    );
  }
}

export default HomePage

const ResponsiveContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 0 20px rgba(225,255,255,0.5);

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
const VideoContainer = styled.div`
  ${ media.large } {
    max-width: 60vh;
  }
  margin: auto;
`

const Container = styled.div`
  ${ media.large } {
    padding-top: 4vh;
  }
`
