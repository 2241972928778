const namedRoutes = { // name : path
  'home' : '/',
  'info' : '/inform',
  'imprint_privacy' : '/imprint_privacy'
}

const getRouteName = (path) => {
  for (let r of Object.entries(namedRoutes)) {
    if (r[1] == path) return r[0]
  }
  console.warn(`route not found`)
  return null
}

export { 
  namedRoutes,
  getRouteName
 }