const dist = {
  rem: 16,
  largeWidth: 640,
  xLargeWidth: 1280,
}

const media = {
  xLarge: `@media (min-width: ${dist.xLargeWidth / 16}rem)`,
  large: `@media (min-width: ${dist.largeWidth / 16}rem)`,
  medium: `@media (max-width: ${(dist.largeWidth-1) / 16}rem)`,
}

export {
  dist,
  media
}