import React, { Component } from 'react';
import styled from 'styled-components/macro'
import openSocket from 'socket.io-client';
import { throttle } from 'throttle-debounce';
import base62 from "base62/lib/ascii";

import { networkConfig } from '../config/network';

const gridFactor = 1 // higher number <-> less grid
const throttleMillis = 25
const pointerDistance = 1 // minimum distance between pointers
const cleanupInterval = 120 // how often an old line shall be removed
const maxPointers = 256

const colors = [
  'rgba(255,100,100,0.4)',
  'rgba(229,96,55,0.4)',
  'rgba(189,140,70,0.4)',
  'rgba(200,40,120,0.4)',
  'rgba(216,131,70,0.4)',
]

const translationTable = [
  'mousemove',
  'touchmove'
]

const compressMsg = obj => {
  return [
    base62.encode(obj.id),
    base62.encode(obj.x),
    base62.encode(obj.y),
    //translationTable.indexOf(obj.type),
  ].join('|')
}

const expandMsg = msg => {
  const data = String(msg).split('|')
  return {
    id: base62.decode(data[0]),
    x: base62.decode(data[1]) / gridFactor,
    y: base62.decode(data[2]) / gridFactor,
    //type: translationTable[data[3]],
  }
}

class Mice extends Component {
  constructor() {
    super()
    this.state = {
      id: null,
      pointers: []
    }
    this.counter = 0
    this.socket = null
    this.receivedId = this.receivedId.bind(this)
    this.received = this.received.bind(this)
    this.event = this.event.bind(this)
    this.cleanup = this.cleanup.bind(this)
    this.renderSvgPointer = this.renderSvgPointer.bind(this)
    this.eventDebounced = throttle(throttleMillis, this.event)
    this.lastPointersById = {}
    this.lastPointerSent = {}
  }

  componentDidMount() {
    this.socket = openSocket(networkConfig.miceUrl);
    this.socket.on('m', this.received);
    this.socket.on('m-id', this.receivedId);
  }

  cleanup() {
    if (!this.cleanupTimeout && this.state.pointers.length > maxPointers ) {
      this.setState({
        pointers: [...this.state.pointers.slice(1)]
      },() => {
        if (!this.cleanupTimeout) {
          this.cleanupTimeout = setTimeout(this.cleanup, cleanupInterval)
        }
      })
    } else {
      this.cleanupTimeout = null
    }
  }

  received(msg) {
    // console.log("recd", msg)

    const obj = {
      ...expandMsg(msg),
      key: this.counter
    }

    /*
    // add previous coordinates
    const lastObj = this.lastPointersById[obj.id] || null
    if (lastObj) {
      obj.lastX = lastObj.x
      obj.lastY = lastObj.y
    }
    this.lastPointersById[obj.id] = obj
    */

    this.counter++
    this.setState({
      pointers: [...this.state.pointers, obj]
    })

    this.cleanup()
    // console.log(obj)
  }

  receivedId(id) {
    console.log("received id " + id)
    this.setState({id})
  }

  event(event) {

    // console.log(event)

    const eventCoords = (event.targetTouches || [event] )

    for (let eC of eventCoords) {
      const out = {
        type: event.type,
        x: Math.floor( gridFactor * 100 * eC.clientX / window.innerWidth),
        y: Math.floor( gridFactor * 100 * eC.clientY / window.innerHeight),
        id: this.state.id,
      }
      if (this.lastPointerSent) {
        const last = this.lastPointerSent
        if (last.x === out.x && last.y === out.y ) {
          return null; // don't send if equal
        }
        const distance = Math.hypot(out.x-last.x, out.y-last.y)
        if (distance < pointerDistance ) {
          return null; // don't send if too close
        }
      }
      this.lastPointerSent = out
      // console.log("send", out)
      this.socket.emit('m', compressMsg(out))
    }
  }

  renderSvgPointer(obj) {
    return <circle 
    key={obj.key}
    cx={obj.x + "%"}
    cy={obj.y + "%"}
    r="4px"
    style={{
      fill: colors[obj.id%colors.length],
      stroke: "rgba(100,255,255,0.2)",
      strokeWidth: 1,
    }} />
  }

  render() {    
    return <MiceCanvas 
      onMouseMove={event => {event.persist(); this.eventDebounced(event)}}
      onTouchMove={event => {event.persist(); this.eventDebounced(event)}}
    >
      {/*this.state.pointers.map(this.renderPointer)*/}

      <Svg style={{width:"100%", height:"100%"}}>
       {this.state.pointers.map(this.renderSvgPointer)}
       {/*this.state.pointers.map(this.renderSvgLine)*/}
      </Svg>      
    </MiceCanvas>
  }
}

export default Mice

const MiceCanvas = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  // background-color: rgba(255,0,0,0.3)
`
const Svg = styled.svg`
  opacity: 0.8;
`