import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components/macro';

import { media } from '../config/globalStyles'

class ImprintPage extends Component {

  render() {    

    return (
      <Markdown>
        <ReactMarkdown source={this.props.data.page_imprint_text} />
        <ReactMarkdown source={this.props.data.page_privacy_text} />
      </Markdown>
    );
  }
}

export default ImprintPage

const Markdown = styled.div`
  text-align: left;
  font-size: 16px;
  ${ media.large } {
    columns: 2;
  }
  h1,h2,p,ol {
    margin-bottom: 1rem;
  }
  h1,h2,h3 {
    font-weight: bold;
  }  
  li {
    &:before {
      content: " * ";
      font-size: 150%;
      top: 0.5ex;
      position: relative;
    }
    &:not(:first-of-type) {
      margin-top: 1ex;
    }
  }
  a {
    word-wrap: break-word;
  }
`